import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Container, Paper } from '@mui/material';
import Grid from '@mui/material/Grid2';

import useMediaQuery from '@mui/material/useMediaQuery';

import { withStyles } from '@mui/styles';

import { sub } from 'date-fns';
import { DataFrame } from 'data-forge';

import { getHomeData, getHueData, getMeterReadings } from '../api/home';
import PlotlyFeatureChart from '../components/charts/PlotlyFeatureChart';
import { hueSatBriToHex, miredToHex } from '../utils';
import HomeEnergyPanel from '../components/HomeEnergyPanel';
import LiveEnergyPlot from '../components/LiveEnergyPlot';

const styles = {
  selector: {
    zIndex: 3,
    position: 'absolute',
    top: '95px',
    left: '7%'
  }
};

const energyDays = 14;
const atmosphericDuration = '6h';

function Home() {
  // const options = {
  //   Temperature: {
  //     label: 'Temperature',
  //     unit: '°C',
  //     colourScale: {
  //       5: 'lightblue',
  //       20: 'yellow',
  //       25: 'orange',
  //       30: 'red'
  //     }
  //   },
  //   Humidity: {
  //     label: 'Humidity',
  //     unit: '%',
  //     colourScale: {
  //       0: 'lightblue',
  //       100: 'darkblue'
  //     }
  //   },
  //   Pressure: {
  //     label: 'Pressure',
  //     unit: 'hPa',
  //     colourScale: {
  //       980: 'yellow',
  //       1020: 'red'
  //     }
  //   },
  //   Noise: {
  //     label: 'Noise',
  //     unit: 'RMA',
  //     plotType: 'bar'
  //   },
  //   Voltage: {
  //     label: 'Voltage',
  //     unit: 'V'
  //   },
  //   IR: {
  //     label: 'Light - IR',
  //     unit: ''
  //   },
  //   Visible: {
  //     label: 'Light - Visible',
  //     unit: ''
  //   },
  //   UV: {
  //     label: 'Light - UV',
  //     unit: ''
  //   },
  //   Download: {
  //     label: 'Internet - Download',
  //     unit: 'Mbps',
  //     plotType: 'bar'
  //   },
  //   Upload: {
  //     label: 'Internet - Upload',
  //     unit: 'Mbps',
  //     plotType: 'bar'
  //   },
  //   Ping: {
  //     label: 'Internet - Ping',
  //     unit: 'ms',
  //     plotType: 'bar'
  //   }
  // };

  // const dataMappings = {
  //   outside: 'Garden',
  //   livingRoom: 'Living Room',
  //   kitchen: 'Kitchen',
  //   hall: 'Hallway',
  //   officeN: "Nick's Office",
  //   officeL: "Lauren's Office",
  //   landing1: '1st Floor Landing',
  //   landing2: '2nd Floor Landing',
  //   bedroom: 'Bedroom'
  // };

  // const [variable, setVariable] = useState('Temperature');
  // const [latestValues, setLatestValues] = useState({});

  const emptySeries = { x: [], y: [] };

  const [tempSeries, setTempSeries] = useState(emptySeries);
  const [humiditySeries, setHumiditySeries] = useState(emptySeries);
  const [dailyElectricity, setDailyElectricity] = useState(emptySeries);
  const [dailyGas, setDailyGas] = useState(emptySeries);
  const [hourlyElectricity, setHourlyElectricity] = useState(emptySeries);
  const [hourlyGas, setHourlyGas] = useState(emptySeries);
  const [lightStates, setLightStates] = useState([]);

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const outsideFields = ['Garden'];
  const metaFields = ['Time'];

  const getMeanInsideValues = (row) => {
    const insideFields = Object.keys(row).filter(
      (field) => !outsideFields.includes(field) && !metaFields.includes(field)
    );
    const insideValues = Object.entries(row)
      .filter(
        ([field, value]) => insideFields.includes(field) && value !== null
      )
      .map(([, value]) => value);
    return parseFloat(
      (insideValues.reduce((a, b) => a + b, 0) / insideValues.length)
        .toFixed(2)
        .toString()
    );
  };

  useEffect(async () => {
    const data = (await getHomeData('Temperature', atmosphericDuration))
      .slice(1)
      .filter((row) => row.Time !== null)
      .map((row) => ({
        ...row,
        Time: new Date(row.Time)
      }));

    setTempSeries({
      x: data.map((row) => row.Time),
      y: data.map(getMeanInsideValues)
    });
  }, []);

  useEffect(async () => {
    const data = (await getHomeData('Humidity', atmosphericDuration))
      .slice(1)
      .filter((row) => row.Time !== null)
      .map((row) => ({
        ...row,
        Time: new Date(row.Time)
      }));

    setHumiditySeries({
      x: data.map((row) => row.Time),
      y: data.map(getMeanInsideValues)
    });
  }, []);

  useEffect(async () => {
    let endDate = new Date();
    endDate.setHours(0, 0, 0, 0);
    // Query currently brings back all time up to endDate, but readings are half-hourly
    endDate = sub(endDate, { minutes: 30 });
    const startDate = sub(endDate, { days: energyDays });
    startDate.setHours(0, 0, 0, 0);

    console.log(startDate);
    console.log(endDate);

    const rawData = await Promise.all(
      ['electricity', 'gas'].map((fuel) =>
        getMeterReadings(fuel, startDate, endDate)
      )
    );

    console.log(rawData);

    const [dailyElectricityData, dailyGasData] = rawData.map((data) => {
      const df = new DataFrame({
        values: data
      })
        .groupBy((row) => row.date)
        .select((group) => ({
          date: new Date(group.first().date),
          consumption: group.deflate((row) => row.consumption).sum()
        }))
        .inflate();

      return df.toArray();
    });

    const [hourlyElectricityData, hourlyGasData] = rawData.map((data) => {
      const df = new DataFrame({
        values: data
      })
        .groupBy((row) => row.hour)
        .select((group) => ({
          hour: `${group.first().hour}:00`,
          consumption: group.deflate((row) => row.consumption).average()
        }))
        .inflate();

      return df.toArray();
    });

    setDailyElectricity({
      x: dailyElectricityData.map((row) => row.date),
      y: dailyElectricityData.map((row) => row.consumption)
    });

    setDailyGas({
      x: dailyGasData.map((row) => row.date),
      y: dailyGasData.map((row) => row.consumption)
    });

    setHourlyElectricity({
      x: hourlyElectricityData.map((row) => row.hour),
      y: hourlyElectricityData.map((row) => row.consumption)
    });

    setHourlyGas({
      x: hourlyGasData.map((row) => row.hour),
      y: hourlyGasData.map((row) => row.consumption)
    });
  }, []);

  const calculateHex = (group) => {
    if (!group.on) return '#000000';

    switch (group.mode) {
      case 'ct':
        return miredToHex(group.colourTemperature);
      case 'hs':
        return hueSatBriToHex(
          (group.hue * 360) / 65535,
          (group.saturation - 1) / 253,
          1
        );
      // case 'xy':

      default:
        return '#000000';
    }
  };

  useEffect(async () => {
    const data = (await getHueData())
      .map((group) => ({
        name: group.name,
        type: group.type,
        on: group['action.on'],
        brightness: group['action.bri'],
        mode: group['action.colormode'],
        colourTemperature: group['action.ct'],
        hue: group['action.hue'],
        saturation: group['action.sat'],
        xy: group['action.xy']
      }))
      .map((group) => ({
        ...group,
        hex: calculateHex(group)
      }));

    console.log(data);

    setLightStates(data);
  }, []);

  return (
    <Container maxWidth="xl">
      <Grid
        container
        direction="row"
        alignContent="center"
        justifyContent="space-around"
        spacing={5}
      >
        <Grid size={{ xs: 12, sm: 4 }}>
          <Paper>
            <PlotlyFeatureChart
              variable="Temperature"
              unit="°C"
              series={tempSeries}
              prefersDarkMode={prefersDarkMode}
            />
          </Paper>
        </Grid>
        <Grid size={{ xs: 12, sm: 4 }}>
          <Paper>
            <PlotlyFeatureChart
              variable="Humidity"
              unit="%"
              series={humiditySeries}
              lineColour="rgb(50, 125, 255)"
              prefersDarkMode={prefersDarkMode}
            />
          </Paper>
        </Grid>
        <Grid size={12}>
          <Paper>
            <LiveEnergyPlot />
          </Paper>
        </Grid>
        <Grid size={12}>
          <Paper>
            <HomeEnergyPanel
              dailyElectricity={dailyElectricity}
              dailyGas={dailyGas}
              hourlyElectricity={hourlyElectricity}
              hourlyGas={hourlyGas}
              prefersDarkMode={prefersDarkMode}
            />
          </Paper>
        </Grid>
        <Grid size={12}>
          <Paper>
            {false &&
              lightStates
                .filter((group) => group.type === 'Room')
                .map((group) => (
                  <div
                    key={group.name}
                    style={{
                      color: group.hex,
                      backgroundColor: 'darkgrey',
                      fontWeight: 'bold'
                    }}
                  >
                    {group.mode === 'ct'
                      ? `${group.name} - ${group.colourTemperature} mired -> ${group.hex}`
                      : `${group.name} - HSL(${group.hue}, ${group.saturation}, ${group.brightness}) -> ${group.hex}`}
                  </div>
                ))}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

Home.propTypes = {
  classes: PropTypes.shape({
    selector: PropTypes.string
  }).isRequired
};

export default withStyles(styles)(Home);
