import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Paper, Typography } from '@mui/material';
import Plot from 'react-plotly.js';
import chroma from 'chroma-js';
import { grey } from '@mui/material/colors';
import { handlePlotlyDarkMode } from '../../utils/themeUtils';

const getColour = (scale, value, benchmark, range) => {
  if (value && benchmark) {
    const colourScale = chroma
      .scale(scale)
      .domain([benchmark - range, benchmark + range / 2])
      .mode('hsl');
    return colourScale(value).hex();
  }

  return null;
};

function PlotlyProgress({
  scale,
  text,
  subText,
  current,
  benchmark,
  deltaReference,
  range,
  target,
  max,
  width,
  height,
  darkMode
}) {
  const plotRef = useRef(null);
  const [plotWidth, setPlotWidth] = useState(width);

  useEffect(() => {
    if (plotRef?.current) {
      setPlotWidth(plotRef.current.getBoundingClientRect().width);
    }
  }, [setPlotWidth]);

  window.addEventListener('resize', () => {
    if (plotRef?.current) {
      setPlotWidth(plotRef.current.getBoundingClientRect().width);
    }
  });

  return (
    <Paper ref={plotRef}>
      <Plot
        data={[
          {
            type: 'indicator',
            mode: 'number+gauge+delta',
            gauge: {
              shape: 'bullet',
              bar: { color: getColour(scale, current, benchmark, range) },
              steps: [{ range: [0, benchmark], color: darkMode ? grey[800] : grey[300] }],
              threshold: {
                line: {
                  color: 'red',
                  width: 1.5
                },
                thickness: 1,
                value: target
              },
              axis: {
                range: [0, max]
              }
            },
            delta: { reference: deltaReference },
            value: current,
            domain: { x: [0, 1], y: [0, 1] },
            title: { text }
          }
        ]}
        layout={handlePlotlyDarkMode({
          width: plotWidth,
          height: height ?? 100,
          margin: {
            l: 120,
            r: 10,
            t: 25,
            b: 25
          },
          plot_bgcolor: 'lightgrey'
        }, darkMode)}
        config={{
          displayModeBar: false
        }}
      />
      <Typography variant="subtitle1">{subText}</Typography>
    </Paper>
  );
}

PlotlyProgress.propTypes = {
  scale: PropTypes.arrayOf(PropTypes.string),
  text: PropTypes.string.isRequired,
  subText: PropTypes.string.isRequired,
  current: PropTypes.number.isRequired,
  benchmark: PropTypes.number.isRequired,
  deltaReference: PropTypes.number.isRequired,
  range: PropTypes.number,
  target: PropTypes.number,
  max: PropTypes.number.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  darkMode: PropTypes.bool
};

PlotlyProgress.defaultProps = {
  scale: ['#ff3300', '#ff8a13', 'green'],
  range: 25,
  target: 100,
  width: 590,
  height: 100,
  darkMode: false
};

export default PlotlyProgress;
