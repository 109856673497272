/* eslint-disable import/prefer-default-export */
import { makeRequest } from './base';

const getHomeData = async (variable, duration) => {
  const data = await makeRequest('POST', 'home-data/Home', {
    variable,
    duration
  });

  // I've inexplicably double-JSONed data from the backend...
  return JSON.parse(data);
};

const getMeterReadings = async (energyType, startDate, endDate) => {
  const data = await makeRequest('POST', 'home-data/energy/meter-readings', {
    energy_type: energyType,
    start_date: startDate.toISOString(),
    end_date: endDate.toISOString()
  });

  // I've inexplicably double-JSONed data from the backend...
  return JSON.parse(data);
};
const getHueData = () => makeRequest('GET', 'home-data/Hue');
const sendHueCommand = (data) =>
  makeRequest('POST', 'home-data/Hue/group', data);

const sendDeskCommand = (data) =>
  makeRequest('POST', 'home-data/Desk', data);

const callParticleFunction = (deviceId, functionName, argument) => {
  const payload = {
    deviceId,
    function: functionName,
    argument
  };
  return makeRequest('POST', 'home-data/particle/call-function', payload);
}

export {
  getHomeData,
  getMeterReadings,
  getHueData,
  sendHueCommand,
  sendDeskCommand,
  callParticleFunction
};
