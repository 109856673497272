/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb as faLightbulbOn } from '@fortawesome/free-regular-svg-icons';
import { faLightbulb as faLightbulbOff } from '@fortawesome/free-solid-svg-icons';

import { Button, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

import { getMonth } from "date-fns";

import { hexToMired, hexToHSV, hueSatBriToHex } from '../utils';
import { getHueData, sendHueCommand, sendDeskCommand, callParticleFunction } from '../api/home';

const lockColour = '#000000';
const unlockColour = '#ffa05a';

const backlightPresets = [
  {
    id: 'unlock',
    name: 'Unlock',
    icon: faLightbulbOn
  },
  {
    id: 'lock',
    name: 'Lock',
    icon: faLightbulbOff
  }
];

const huePresets = [
  {
    id: 'on',
    name: 'On',
    icon: faLightbulbOn
  },
  {
    id: 'off',
    name: 'Off',
    icon: faLightbulbOff
  }
];

const christmasPresets = [
  {
    id: 'FLURRY_PASTEL',
    name: 'Flurry Pastel',
    icon: faLightbulbOn
  },
  {
    id: 'FLURRY_FIRE',
    name: 'Flurry Fire',
    icon: faLightbulbOn
  },
  {
    id: 'OFF',
    name: 'Off',
    icon: faLightbulbOff
  }
]

const hueGroupId = 7;

function BigButton({ ...props }) {
  return (
    <Button
      {...props}
      sx={{
        height: '80px',
        width: '150px',
        fontSize: '14pt'
      }}
    />
  );
}

function BigInput({ ...props }) {
  return (
    <Paper elevation={1} square={false} sx={{ height: '80px', width: '150px' }}>
      <input
        {...props}
        style={{
          height: '100%',
          width: '100%',
          fontSize: '14pt',
          border: 0,
          padding: 0,
          cursor: 'pointer',
          backgroundColor: '#00796b'
        }}
      />
    </Paper>
  );
}

function Desk() {
  const [requestPending, setRequestPending] = useState(false);

  const [backlightColour, setBacklightColour] = useState(unlockColour);
  const [hueColour, setHueColour] = useState(unlockColour);

  const isChristmas = getMonth(new Date()) === 11;

  const sendPhotonCommand = async (name, value) => {
    const data = {
      category: name,
      command: value
    };

    console.log(data);

    if (!requestPending) {
      setRequestPending(true);

      try {
        const responseData = await sendDeskCommand(data);

        console.log(responseData);
        if (name === 'backlight') {
          if (value === 'lock') {
            setBacklightColour(lockColour);
          } else if (value === 'unlock') {
            setBacklightColour(unlockColour);
          } else {
            setBacklightColour(value);
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        setRequestPending(false);
      }
    }
  };

  const handleBacklightColourChange = (event) => {
    const colour = event.target.value;
    sendPhotonCommand('backlight', colour);
  };

  const getHueColour = async () => {
    const data = await getHueData();
    const group = data.find((g) => g.api_id === `${hueGroupId}`);
    if (group) {
      console.log(group);
      if (!requestPending) setRequestPending(true);
      // const colourMode = group['action.colormode'];
      const hue = parseInt((group['action.hue'] * 360) / 65535);
      const sat = group['action.sat'] / 254;
      const bri = group['action.bri'] / 254;
      const hex = hueSatBriToHex(hue, sat, bri);
      setHueColour(hex);
      setRequestPending(false);
    }
  };

  const sendHueGroupCommand = async (command, value) => {
    console.log(`Command: ${command}, Value: ${value}`);

    const hueState = {
      on: true
    };

    if (command === 'off') hueState.on = false;
    if (command === 'colour') {
      hueState.ct = hexToMired(value);

      const hsv = hexToHSV(value);
      hueState.hue = parseInt((hsv[0] * 65535) / 360);
      hueState.sat = parseInt(hsv[1] * 254);
    }

    if (!requestPending) {
      try {
        await sendHueCommand({
          group_id: hueGroupId,
          state: hueState
        });
        getHueColour();
      } catch (err) {
        console.error(err);
      } finally {
        setRequestPending(false);
      }
    }
  };

  const handleHueColourChange = (event) => {
    const colour = event.target.value;
    sendHueGroupCommand('colour', colour);
  };

  const sendParticleCommand = (command) => {
    callParticleFunction('e00fce68051d76f4f1d9ebf0', 'changeSetting', command);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-evenly"
      alignItems="center"
      spacing={3}
    >
      <Grid>
        <Typography variant="h4" gutterBottom>
          Backlight
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={3}
        >
          {backlightPresets.map((preset) => (
            <Grid key={preset.id}>
              <BigButton
                variant="contained"
                startIcon={<FontAwesomeIcon icon={preset.icon} />}
                onClick={() => sendPhotonCommand('backlight', preset.id)}
              >
                <Typography>{preset.name}</Typography>
              </BigButton>
            </Grid>
          ))}
          <Grid key="backlightColour">
            <BigInput
              id="colourPicker"
              type="color"
              value={backlightColour}
              onChange={handleBacklightColourChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <Typography variant="h4" gutterBottom>
          Hue
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={3}
        >
          {huePresets.map((preset) => (
            <Grid key={preset.id}>
              <BigButton
                variant="contained"
                startIcon={<FontAwesomeIcon icon={preset.icon} />}
                onClick={() => sendHueGroupCommand(preset.id)}
              >
                <Typography>{preset.name}</Typography>
              </BigButton>
            </Grid>
          ))}
          <Grid key="hueColour">
            <BigInput
              id="colourPicker"
              type="color"
              value={hueColour}
              onChange={handleHueColourChange}
            />
          </Grid>
        </Grid>
      </Grid>
      {
        isChristmas && (
          <Grid>
            <Typography variant="h4" gutterBottom>
              Christmas Window Lights
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={3}
            >
              {christmasPresets.map((preset) => (
                <Grid key={preset.id}>
                  <BigButton
                    variant="contained"
                    startIcon={<FontAwesomeIcon icon={preset.icon} />}
                    onClick={() => sendParticleCommand(preset.id)}
                  >
                    <Typography>{preset.name}</Typography>
                  </BigButton>
                </Grid>
              ))}
            </Grid>
          </Grid>)
      }
    </Grid>
  );
}

export default Desk;
