import React, {useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { DataFrame } from 'data-forge';
import moment from 'moment';
import Plot from 'react-plotly.js';

import { handlePlotlyDarkMode } from '../../utils/themeUtils';

function FitbarkActivityBars({ activityData, prefersDarkMode }) {
    const plotRef = useRef(null);
    const [plotWidth, setPlotWidth] = useState(null);
  
    useEffect(() => {
      if (plotRef?.current) {
        setPlotWidth(plotRef.current.getBoundingClientRect().width);
      }
    }, [setPlotWidth]);
  
    window.addEventListener('resize', () => {
      if (plotRef?.current) {
        setPlotWidth(plotRef.current.getBoundingClientRect().width);
      }
    });

    const df = new DataFrame(activityData)
      .generateSeries({
        date_date: (row) => moment(row.date).format('YYYY-MM-DD')
      })
      .groupBy((row) => row.date_date)
      .select((group) => ({
        date_date: group.first().date_date,
        hours_rest: group.deflate((row) => row.min_rest).sum() / 60,
        hours_active: group.deflate((row) => row.min_active).sum() / 60,
        hours_play: group.deflate((row) => row.min_play).sum() / 60
      }))
      .inflate()
      .generateSeries({
        restLabel: (row) => {
          const dateLabel = moment(row.date_date).format('ddd Do MMM');
          return `${dateLabel}<br />Rest: ${row.hours_rest.toFixed(1)} hours`;
        },
        activeLabel: (row) => {
          const dateLabel = moment(row.date_date).format('ddd Do MMM');
          return `${dateLabel}<br />Active: ${row.hours_active.toFixed(
            1
          )} hours`;
        },
        playLabel: (row) => {
          const dateLabel = moment(row.date_date).format('ddd Do MMM');
          return `${dateLabel}<br />Play: ${row.hours_play.toFixed(1)} hours`;
        }
      });

    const traces = [
      {
        x: df.getSeries('date_date').toArray(),
        y: df.getSeries('hours_rest').toArray(),
        text: df.getSeries('restLabel').toArray(),
        textposition: 'none',
        hoverinfo: 'text',
        name: 'Rest',
        type: 'bar',
        showlegend: true
      },
      {
        x: df.getSeries('date_date').toArray(),
        y: df.getSeries('hours_active').toArray(),
        text: df.getSeries('activeLabel').toArray(),
        textposition: 'none',
        hoverinfo: 'text',
        name: 'Active (non-play)',
        type: 'bar',
        showlegend: true
      },
      {
        x: df.getSeries('date_date').toArray(),
        y: df.getSeries('hours_play').toArray(),
        text: df.getSeries('playLabel').toArray(),
        textposition: 'none',
        hoverinfo: 'text',
        name: 'Play',
        type: 'bar',
        showlegend: true
      }
    ];

    const layout = handlePlotlyDarkMode({
      width: plotWidth,
      barmode: 'stack',
      showlegend: true,
      xaxis: {
        title: ''
      },
      yaxis: {
        title: 'Time [hours]',
        fixedrange: true,
        range: [0, 24]
      },
      title: 'Daily Activity',
      autosize: true,
      legend: {
        orientation: 'h'
      }
    }, prefersDarkMode);

    return <div ref={plotRef}><Plot data={traces} layout={layout} useResizeHandler /></div>;
}

FitbarkActivityBars.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    activityData: PropTypes.arrayOf(PropTypes.any).isRequired,
    prefersDarkMode: PropTypes.bool
};

FitbarkActivityBars.defaultProps = {
    prefersDarkMode: false
};

export default FitbarkActivityBars;
