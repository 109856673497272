import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Typography, Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid2';

import Plot from 'react-plotly.js';

import { format } from 'date-fns';
import { handlePlotlyDarkMode } from '../utils/themeUtils';

function HomeEnergyPanel({
  dailyElectricity,
  dailyGas,
  hourlyElectricity,
  hourlyGas,
  prefersDarkMode
}) {
  const [plotWidth, setPlotWidth] = useState(null);
  const plotRef = useRef(null);

  // Workaround to make plots display more nicely
  const modGas = {
    x: [...hourlyGas.x, '24:00'],
    y: [...hourlyGas.y, hourlyGas.y[0]]
  };

  const modElectricity = {
    x: [...hourlyElectricity.x, '24:00'],
    y: [...hourlyElectricity.y, hourlyElectricity.y[0]]
  };

  const generateDailyLabels = (series, unit) =>
    series.x.map((date, i) => {
      const value = series.y[i];
      return `${value.toFixed(2)} ${unit}<br />${format(date, 'EEE do MMM')}`;
    });

  const generateHourlyLabels = (series, unit, dp = 2) =>
    series.x.map((hour, i) => {
      const value = series.y[i];
      return value && `${value.toFixed(dp)} ${unit}<br />${hour}`;
    });

  const dailyData = [
    {
      name: 'Electricity',
      x: dailyElectricity.x,
      y: dailyElectricity.y,
      width: 86400000 / 3,
      // offset: -8640000 * 2,
      text: generateDailyLabels(dailyElectricity, 'kWh'),
      textposition: 'none',
      hoverinfo: 'text',
      type: 'bar',
      marker: {
        color: 'orange'
      }
    },
    {
      name: 'Gas',
      x: dailyGas.x,
      y: dailyGas.y,
      width: 86400000 / 3,
      offset: 8640000 * 2,
      text: generateDailyLabels(dailyGas, 'kWh'),
      textposition: 'none',
      hoverinfo: 'text',
      type: 'bar',
      marker: {
        color: '#00ccff'
      },
      yaxis: 'y2'
    }
  ];

  const dailyLayout = handlePlotlyDarkMode({
    width: plotWidth,
    // height,
    margin: {
      l: 50,
      r: 50,
      t: 50,
      b: 50
    },
    xaxis: {
      showgrid: false
    },
    yaxis: {
      title: 'Electricity Consumption [kWh]',
      showgrid: false,
      fixedrange: true
    },
    yaxis2: {
      title: 'Gas Consumption [kWh]',
      showgrid: false,
      fixedrange: true,
      overlaying: 'y',
      side: 'right'
    },
    barMode: 'group',
    legend: {
      orientation: 'h'
    }
  }, prefersDarkMode);

  const config = {
    displayModeBar: false
  };

  const hourlyData = [
    {
      name: 'Electricity',
      x: modElectricity.x,
      y: modElectricity.y,
      text: generateHourlyLabels(modElectricity, 'kWh'),
      textposition: 'none',
      hoverinfo: 'text',
      mode: 'lines',
      line: {
        // shape: 'spline',
        color: 'orange',
        shape: 'hv'
      },
      fill: 'tozeroy'
    },
    {
      name: 'Gas',
      x: modGas.x,
      y: modGas.y,
      text: generateHourlyLabels(modGas, 'kWh', 3),
      textposition: 'none',
      hoverinfo: 'text',
      mode: 'lines',
      line: {
        // shape: 'spline',
        color: '#00ccff',
        shape: 'hv'
      },
      fill: 'tozeroy',
      yaxis: 'y2'
    }
  ];

  const hourlyLayout = handlePlotlyDarkMode({
    width: plotWidth,
    // height,
    margin: {
      l: 50,
      r: 50,
      t: 50,
      b: 50
    },
    xaxis: {
      showgrid: false,
      range: [0, 24]
    },
    yaxis: {
      title: 'Average Electricity Consumption [kWh]',
      showgrid: false,
      fixedrange: true
    },
    yaxis2: {
      title: 'Average Gas Consumption [kWh]',
      showgrid: false,
      fixedrange: true,
      overlaying: 'y',
      side: 'right'
    },
    barMode: 'group',
    legend: {
      orientation: 'h'
    }
  }, prefersDarkMode);

  useEffect(() => {
    if (plotRef && plotRef.current) {
      setPlotWidth(plotRef.current.getBoundingClientRect().width);
    }
  }, [setPlotWidth, plotRef]);

  window.addEventListener('resize', () => {
    if (plotRef && plotRef.current) {
      setPlotWidth(plotRef.current.getBoundingClientRect().width);
    }
  });

  return (
    <Grid container direction="column" spacing={0} padding={3}>
      <Grid
        container
        direction="row"
        spacing={0}
        justifyContent="space-between"
      >
        <Grid size={7}>
          <Typography variant="h5" align="left">
            Energy
          </Typography>
        </Grid>
      </Grid>
      {/* xs prop below still determines element width, even though the direction of its parent Grid component is 'column' */}
      <Grid size={12} ref={plotRef}>
        {dailyElectricity.x.length === 0 || dailyGas.x.length === 0 ? (
          <Skeleton variant="rectangular" width="100%" height={450} />
        ) : (
          <Plot data={dailyData} layout={dailyLayout} config={config} />
        )}
      </Grid>
      <Grid size={12}>
        {hourlyElectricity.x.length === 0 || hourlyGas.x.length === 0 ? (
          <Skeleton variant="rectangular" width="100%" height={450} />
        ) : (
          <Plot data={hourlyData} layout={hourlyLayout} config={config} />
        )}
      </Grid>
    </Grid>
  );
}

HomeEnergyPanel.propTypes = {
  dailyElectricity: PropTypes.shape({
    x: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
    y: PropTypes.arrayOf(PropTypes.number).isRequired
  }).isRequired,
  dailyGas: PropTypes.shape({
    x: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
    y: PropTypes.arrayOf(PropTypes.number).isRequired
  }).isRequired,
  hourlyElectricity: PropTypes.shape({
    x: PropTypes.arrayOf(PropTypes.string).isRequired,
    y: PropTypes.arrayOf(PropTypes.number).isRequired
  }).isRequired,
  hourlyGas: PropTypes.shape({
    x: PropTypes.arrayOf(PropTypes.string).isRequired,
    y: PropTypes.arrayOf(PropTypes.number).isRequired
  }).isRequired,
  prefersDarkMode: PropTypes.bool
};

HomeEnergyPanel.defaultProps = {
  prefersDarkMode: false
};

export default HomeEnergyPanel;
