import React, { useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';

import { DataFrame } from 'data-forge';
import moment from 'moment';
import Plot from 'react-plotly.js';

import { titleCase, getColourScale } from '../../utils';
import { handlePlotlyDarkMode } from '../../utils/themeUtils';

const generateLabel = (row) => {
    const date = moment(row.date).format('ddd Do MMM HH:00');
    const elements = [date];

    const activityTypes = {
      play: 'min_play',
      active: 'min_active',
      rest: 'min_rest'
    };

    Object.keys(activityTypes).forEach((key) => {
      const activityType = activityTypes[key];
      const mins = row[activityType];
      elements.push(`  ${titleCase(key)}: ${mins} min${mins === 1 ? '' : 's'}`);
    });

    return elements.join('<br />');
  };

function FitbarkActivityMap({ activityData, prefersDarkMode }) {
    const plotRef = useRef(null);
    const [plotWidth, setPlotWidth] = useState(null);
  
    useEffect(() => {
      if (plotRef?.current) {
        setPlotWidth(plotRef.current.getBoundingClientRect().width);
      }
    }, [setPlotWidth]);
  
    window.addEventListener('resize', () => {
      if (plotRef?.current) {
        setPlotWidth(plotRef.current.getBoundingClientRect().width);
      }
    });

    let df = new DataFrame(activityData);
    df = df.generateSeries({
        date_only: (row) => moment(row.date).format('Y-MM-DD'),
        hour_only: (row) => moment(row.date).format('HH:00'),
        min_active_or_play: (row) => row.min_active + row.min_play,
        prop_active_or_play: (row) =>
        (100 * (row.min_active + row.min_play)) /
        (row.min_active + row.min_play + row.min_rest),
        label: generateLabel
    });

    df = df.where(
        (row) => moment(row.date) >= moment().subtract(21, 'days').startOf('day')
    );

    const trace = {
        x: df.getSeries('date_only').toArray(),
        y: df.getSeries('hour_only').toArray(),
        z: df.getSeries('prop_active_or_play').toArray(),
        text: df.getSeries('label').toArray(),
        hoverinfo: 'text',
        type: 'heatmap',
        colorscale: getColourScale(df, 'prop_active_or_play'),
        colorbar: {
        ticksuffix: '%'
        }
    };

    const traces = [trace];

    const layout = handlePlotlyDarkMode({
        width: plotWidth,
        showlegend: true,
        xaxis: {
        title: '',
        fixedrange: true
        },
        yaxis: {
        title: '',
        fixedrange: true,
        // autorange: 'reversed',
        tickmode: 'array',
        tickvals: [0, 4, 8, 12, 16, 20],
        ticktext: ['0:00', '4:00', '8:00', '12:00', '16:00', '20:00']
        },
        title: 'Hourly Activity',
        autosize: true
    }, prefersDarkMode);

    return <div ref={plotRef}><Plot data={traces} layout={layout} useResizeHandler /></div>;
}

FitbarkActivityMap.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    activityData: PropTypes.arrayOf(PropTypes.any).isRequired,
    prefersDarkMode: PropTypes.bool
};

FitbarkActivityMap.defaultProps = {
    prefersDarkMode: false
};

export default FitbarkActivityMap;
