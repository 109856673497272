import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Button,
  Avatar,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  SvgIcon,
  Typography
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { grey } from '@mui/material/colors';

import GoogleAuthButton from './auth/GoogleAuth';

function Navigation({ pages, handleAuthChange, prefersDarkMode }) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar
          sx={{ backgroundColor: prefersDarkMode ? grey[900] : grey[800] }}
        >
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={openDrawer}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', lg: 'flex' },
              alignItems: 'center',
              overflowX: 'auto'
            }}
          >
            <Avatar
              src="/N.png"
              alt="nickstringer.dev"
              imgProps={{ width: 30 }}
              variant="square"
              sx={{ mr: 2 }}
            />
            {pages.map((page) => (
              <Button
                key={page.name}
                href={page.address}
                sx={{
                  my: 2,
                  color: 'white',
                  display: 'block',
                  textAlign: 'center'
                }}
              >
                <Typography>{page.name}</Typography>
              </Button>
            ))}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', lg: 'none' },
              alignItems: 'center'
            }}
          >
            <Avatar
              src="/N.png"
              alt="nickstringer.dev"
              imgProps={{ width: 30 }}
              variant="square"
              sx={{ mr: 2 }}
            />
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <GoogleAuthButton onAuthorisationChanged={handleAuthChange} />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer open={drawerOpen} onClose={closeDrawer}>
        <Box sx={{ width: 250 }} role="presentation" onClick={closeDrawer}>
          <List>
            {pages.map(({ name, address, icon }) => (
              <ListItem key={name} disablePadding>
                <ListItemButton to={address} component={Link}>
                  {icon && (
                    <ListItemIcon>
                      <SvgIcon component={icon} />
                    </ListItemIcon>
                  )}
                  <ListItemText primary={name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}

Navigation.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      component: PropTypes.element,
      icon: PropTypes.element
    })
  ).isRequired,
  handleAuthChange: PropTypes.func.isRequired,
  prefersDarkMode: PropTypes.bool
};

Navigation.defaultProps = {
  prefersDarkMode: false
};

export default Navigation;
