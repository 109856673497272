import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Typography, Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid2';

import Plot from 'react-plotly.js';

import { format } from 'date-fns';
import { handlePlotlyDarkMode } from '../../utils/themeUtils';

function PlotlyFeatureChart({
  variable,
  unit,
  series,
  height,
  lineColour,
  plotType,
  prefersDarkMode
}) {
  const [plotWidth, setPlotWidth] = useState(null);
  const plotRef = useRef(null);

  const latestValue = series.y.at(-1);

  const labels = series.x.map((time, i) => {
    const value = series.y[i];
    return `${value} ${unit}<br />${format(time, 'EEE HH:mm:ss')}`;
  });

  const trace = {
    x: series.x,
    y: series.y,
    text: labels,
    textposition: 'none',
    hoverinfo: 'text',
    type: plotType,
    mode: plotType === 'line' ? 'lines' : undefined,
    line: {
      shape: 'spline',
      color: lineColour
    },
    showlegend: false
  };

  const data = [trace];

  const layout = handlePlotlyDarkMode({
    width: plotWidth,
    height,
    margin: {
      l: 0,
      r: 0,
      t: 0,
      b: 0,
      pad: 0
    },
    xaxis: {
      showgrid: false,
      showticklabels: false
    },
    yaxis: {
      showgrid: false,
      showticklabels: false
    },
    showlegend: false
  }, prefersDarkMode);

  const config = {
    displayModeBar: false
  };

  useEffect(() => {
    if (plotRef && plotRef.current) {
      setPlotWidth(plotRef.current.getBoundingClientRect().width);
    }
  }, [setPlotWidth, plotRef]);

  window.addEventListener('resize', () => {
    if (plotRef && plotRef.current) {
      setPlotWidth(plotRef.current.getBoundingClientRect().width);
    }
  });

  return (
    <Grid container direction="column" spacing={0} padding={3}>
      <Grid
        xs
        container
        direction="row"
        spacing={0}
        justifyContent="space-between"
      >
        <Grid size={7}>
          <Typography variant="h5" align="left">
            {variable}
          </Typography>
        </Grid>
        <Grid size={5}>
          <Typography variant="h5" align="right">
            {latestValue !== undefined
              ? `${latestValue.toFixed(1)} ${unit}`
              : ''}
          </Typography>
        </Grid>
      </Grid>
      {/* xs prop below still determines element width, even though the direction of its parent Grid component is 'column' */}
      <Grid size={12} ref={plotRef}>
        {series.x.length === 0 ? (
          <Skeleton variant="rectangular" width="100%" height={200} />
        ) : (
          <Plot data={data} layout={layout} config={config} />
        )}
      </Grid>
    </Grid>
  );
}

PlotlyFeatureChart.propTypes = {
  variable: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  series: PropTypes.shape({
    x: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
    y: PropTypes.arrayOf(PropTypes.number).isRequired
  }).isRequired,
  height: PropTypes.number,
  lineColour: PropTypes.string,
  plotType: PropTypes.string,
  prefersDarkMode: PropTypes.bool
};

PlotlyFeatureChart.defaultProps = {
  height: 200,
  lineColour: 'rgb(205, 12, 24)',
  plotType: 'line',
  prefersDarkMode: false
};

export default PlotlyFeatureChart;
