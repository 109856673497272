import HomeIcon from '@mui/icons-material/Home';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PetsIcon from '@mui/icons-material/Pets';
import WorkIcon from '@mui/icons-material/Work';
import DeskIcon from '@mui/icons-material/Desk';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import TimerIcon from '@mui/icons-material/Timer';
import NightlightRoundIcon from '@mui/icons-material/NightlightRound';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import Home from '../pages/home';
import Desk from '../pages/office';
// import Inky from '../pages/inky';
import Health from '../pages/health';
import Strava from '../pages/strava';
import Toggl from '../pages/toggl';
import FitBark from '../pages/fitbark';
// import Cam from '../pages/cam';
// import Coral from '../pages/coral';
import Drone from '../pages/drone';
import LLM from '../pages/llm';
import Astro from '../pages/astro';
import Car from '../pages/car';
import Training from '../pages/training';
import Account from '../pages/account';

import { getUserPages } from '../api/user';

export const allPages = [
  { name: 'Home', address: '/home', component: Home, icon: HomeIcon },
  { name: 'Health', address: '/health', component: Health, icon: FavoriteIcon },
  { name: 'Dog', address: '/dog', component: FitBark, icon: PetsIcon },
  { name: 'Work', address: '/work', component: Toggl, icon: WorkIcon },
  { name: 'Office', address: '/office', component: Desk, icon: DeskIcon },
  {
    name: 'Strava',
    address: '/strava',
    component: Strava,
    icon: DirectionsRunIcon
  },
  {
    name: 'Training',
    address: '/training',
    component: Training,
    icon: TimerIcon
  },
  {
    name: 'Astronomy',
    address: '/astronomy',
    component: Astro,
    icon: NightlightRoundIcon
  },
  { name: 'Car', address: '/car', component: Car, icon: DirectionsCarIcon },
  {
    name: 'Drone',
    address: '/drone',
    component: Drone,
    icon: VideogameAssetIcon
  },
  { name: 'LLMs', address: '/llm', component: LLM, icon: AutoAwesomeIcon },
  // { name: "Inky", address: "/inky", component: Inky },
  // { name: "Cam", address: "/cam", component: Cam },
  // { name: "Coral", address: "/coral", component: Coral },
  {
    name: 'Account',
    address: '/account',
    component: Account,
    icon: VerifiedUserIcon
  }
];

export const processUserPages = async () => {
  try {
    const data = await getUserPages();

    let userPages = [];

    if (data.pages === '*') {
      userPages = allPages;
    } else if (Array.isArray(data.pages)) {
      userPages = allPages.filter((p) => data.pages.includes(p.name));
    }

    return userPages;
  } catch (err) {
    console.error(err);
    return [];
  }
};
