import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Container, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';

import useMediaQuery from '@mui/material/useMediaQuery';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStrava } from '@fortawesome/free-brands-svg-icons';

import StravaActivityPicker from '../components/StravaActivityPicker';
import StravaActivityDetail from '../components/charts/StravaActivityDetail';

import { getApiToken } from '../api/base';
import { userAuthorised, storeAuthInfo } from '../api/strava';

function Strava({ location }) {
  const [authorised, setAuthorised] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [selectedActivityType, setSelectedActivityType] = useState(null);

  useEffect(async () => {
    const data = await userAuthorised();
    console.log(data);
    setAuthorised(data.authorised)
  }, [authorised]);

  useEffect(async () => {
    const data = await userAuthorised();
    console.log(data);
    setAuthorised(data.authorised)
  }, [selectedActivity]);

  const handleActivitySelected = (activityId, activityType) => {
    setSelectedActivity(activityId);
    setSelectedActivityType(activityType);
  };

  // eslint-disable-next-line class-methods-use-this
  const authorise = async () => {
    // ToDo: Send redirect to API service and redirect from there back here.

    // const protocol = window.location.protocol;
    // const host = window.location.hostname;
    // const port = window.location.port;

    // const apiUrl = `${protocol}//${host}:${port}/api/Strava`;
    // const apiUrl = 'http://192.168.0.157:81/api/Strava';

    const token = await getApiToken();

    const loginUrl = `http://www.strava.com/oauth/authorize?client_id=47170&response_type=code&redirect_uri=${window.location.href}&approval_prompt=force&scope=read_all,activity:read_all&state=${token}`;
    window.location.replace(loginUrl);
  }

  const storeAuth = async () => {
    const params = new URLSearchParams(location.search);
    const data = await storeAuthInfo({
      code: params.get('code'),
      scopes: params.get('scope'),
      state: params.get('state')
    });
    // console.log(data);
    const { protocol, hostname, port, pathname } = window.location;
    if (data.status && data.status === 'success') {
      const redirectUrl = `${protocol}//${hostname}:${port}${pathname}`;
      console.log(redirectUrl);
      window.location.replace(redirectUrl);
    }
  }

  // ToDo: Handle callback on API side?
  if (location && location.search) {
    storeAuth();
  }

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  return authorised ? (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, lg: 3 }}>
          <StravaActivityPicker onSelect={handleActivitySelected} />
        </Grid>
        <Grid size={{ xs: 12, lg: 9 }}>
          {selectedActivity !== null && (
            <StravaActivityDetail
              activityId={selectedActivity}
              activityType={selectedActivityType}
              prefersDarkMode={prefersDarkMode}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  ) : (
    <Button onClick={authorise}>
      <FontAwesomeIcon icon={faStrava} />
      <span style={{ marginLeft: '10px' }}>Authorise</span>
    </Button>
  );
}

Strava.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
};

export default Strava;
