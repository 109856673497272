import React from 'react';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid2';

import FitbitAuthButton from '../components/auth/FitbitAuth';
import StravaAuthButton from '../components/auth/StravaAuth';
import FitbarkAuthButton from '../components/auth/FitbarkAuth';
import ThreadsAuthButton from '../components/auth/ThreadsAuth';

function Account({ location }) {
  return (
    <Grid container size={12} justifyContent="space-evenly">
      <Grid
        size={{
          xs: 6,
          md: 4
        }}
      >
        <Stack spacing={2}>
          <FitbitAuthButton location={location} />
          <StravaAuthButton location={location} />
          <FitbarkAuthButton location={location} />
          <ThreadsAuthButton location={location} />
        </Stack>
      </Grid>
    </Grid>
  );
}

Account.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
};

export default Account;
