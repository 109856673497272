import React, { useState } from 'react';

import { Helmet } from 'react-helmet';

import { BrowserRouter, Switch, Route } from 'react-router-dom';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';

import Container from '@mui/material/Container';

import { lightTheme, darkTheme } from './utils/themeUtils';

import Navigation from './components/navigation';

import { API_HOST, getApiToken } from './api/base';
import { processUserPages } from './utils/pages';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // const styles = {
  //   textAlign: 'center',
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  //   minHeight: 'calc(100vh - 68px)',
  //   padding: '20px'
  // };

  const [loggedIn, setLoggedIn] = useState(false);
  const [pages, setPages] = useState([]);

  getApiToken();
  const filterPages = async (isLoggedIn) => {
    if (!isLoggedIn) {
      if (pages.length > 0) setPages([]);
      return;
    }

    const userPages = await processUserPages();
    setPages(userPages);
  };

  const processTitle = (path) => {
    if (loggedIn && pages) {
      const filteredPages = pages.filter(
        (p) => p.address === (path ?? window.location.pathname)
      );
      if (filteredPages.length > 0) {
        return `${filteredPages[0].name} - nickstringer.dev`;
      }
    }

    return 'nickstringer.dev';
  };

  const title = processTitle();

  const googleLoggedIn = (gLoggedIn) => {
    console.log('Google logged in', gLoggedIn);
    setLoggedIn(gLoggedIn);
    filterPages(gLoggedIn);
  };

  const setupCSP = () => {
    const localIP = API_HOST ? `${API_HOST} ` : '';
    const csp = {
      'default-src': "'self' accounts.google.com",
      'connect-src': `'self' ${localIP} accounts.google.com api.mapbox.com events.mapbox.com *.openstreetmap.org *.cartocdn.com cloudflareinsights.com`,
      'frame-src': "'self' accounts.google.com",
      'script-src':
        "'self' accounts.google.com ajax.cloudflare.com static.cloudflareinsights.com blob: 'unsafe-inline'",
      'img-src':
        "'self' *.openstreetmap.org *.cartocdn.com *.cloudfront.net data:",
      'media-src': "'self'  *.cartocdn.com",
      'style-src':
        "'self' accounts.google.com api.tiles.mapbox.com *.cartocdn.com 'unsafe-inline'",
      'style-src-elem':
        "'self' accounts.google.com api.tiles.mapbox.com *.cartocdn.com 'unsafe-inline'"
    };

    if (process.env.NODE_ENV === 'development' && API_HOST) {
      csp['connect-src'] += ` ${API_HOST}`;
    }

    return Object.entries(csp)
      .map(([key, value]) => `${key} ${value};`)
      .join('');
  };

  console.log(`Logged in: ${loggedIn}`);

  return (
    <ThemeProvider theme={prefersDarkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <BrowserRouter>
        <Helmet>
          <meta httpEquiv="Content-Security-Policy" content={setupCSP()} />
          <title>{title}</title>
        </Helmet>
        <Navigation
          pages={pages}
          handleAuthChange={(newState) => {
            if (newState !== loggedIn) googleLoggedIn(newState);
          }}
          prefersDarkMode={prefersDarkMode}
        />
        {loggedIn ? (
          <Container
            maxWidth="lg"
            sx={{
              minHeight: 'calc(100vh - 68px)',
              padding: 3,
              textAlign: 'center'
            }}
          >
            <Switch>
              {pages.map((page) => (
                <Route
                  key={page.name.replaceAll(' ', '')}
                  path={page.address}
                  component={page.component}
                />
              ))}
            </Switch>
          </Container>
        ) : null}
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
