import React, { useState } from 'react';

import { TextField, MenuItem, Paper, Stack, FormControl } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid2';

import Markdown from 'react-markdown';

import { generateText } from '../api/llms';

function LLM() {
  const [model, setModel] = useState('gemini-1.5-flash-latest');
  const [prompt, setPrompt] = useState(null);
  const [output, setOutput] = useState(null);
  const [loading, setLoading] = useState(false);

  return (
    <Grid container size={12} justifyContent="space-evenly" spacing={2}>
      <Grid
        size={{
          xs: 12,
          md: 6
        }}
      >
        <Paper sx={{ p: 2 }}>
          <Stack spacing={2}>
            <FormControl variant="filled" fullWidth>
              <TextField
                id="llm-select"
                label="Model"
                variant="outlined"
                value={model}
                onChange={(event) => {
                  setModel(event.target.value);
                }}
                select
                disabled={loading}
              >
                <MenuItem value="gemini-1.5-flash-latest">
                  Gemini 1.5 Flash
                </MenuItem>
                <MenuItem value="gpt-4o">GPT-4o</MenuItem>
              </TextField>
            </FormControl>
            <TextField
              label="Prompt"
              variant="outlined"
              minRows={3}
              multiline
              fullWidth
              onChange={(event) => {
                setPrompt(event.target.value);
              }}
              disabled={loading}
            />
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={async () => {
                console.log(model);
                console.log(prompt);
                setLoading(true);
                setOutput(null);
                const result = await generateText(model, undefined, prompt);
                setOutput(result);
                setLoading(false);
              }}
            >
              Go!
            </LoadingButton>
          </Stack>
        </Paper>
      </Grid>
      {output && (
        <Grid size={12}>
          <Paper sx={{ padding: 5 }}>
            <Markdown>{output}</Markdown>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
}

export default LLM;
