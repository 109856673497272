import React, {useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { DataFrame } from 'data-forge';
import moment from 'moment';
import Plot from 'react-plotly.js';

import { handlePlotlyDarkMode } from '../../utils/themeUtils';

function FitbarkBatteryChart({ data, prefersDarkMode }) {
    const plotRef = useRef(null);
    const [plotWidth, setPlotWidth] = useState(null);
  
    useEffect(() => {
      if (plotRef?.current) {
        setPlotWidth(plotRef.current.getBoundingClientRect().width);
      }
    }, [setPlotWidth]);
  
    window.addEventListener('resize', () => {
      if (plotRef?.current) {
        setPlotWidth(plotRef.current.getBoundingClientRect().width);
      }
    });

    const df = new DataFrame(data).generateSeries({
        date: (row) => moment(row.last_sync).format('YYYY-MM-DD'),
        battery_percentage: (row) => row.battery_level / 100
      });
  
      const traces = [
        {
          x: df.getSeries('date').toArray(),
          y: df.getSeries('battery_percentage').toArray(),
          name: 'Battery Level',
          type: 'scatter',
          fill: 'tozeroy',
          showlegend: false
        }
      ];
      const layout = handlePlotlyDarkMode({
        title: 'Battery Level',
        xaxis: {
          title: ''
        },
        yaxis: {
          title: '',
          fixedrange: true,
          range: [0, 1],
          tickformat: ',.0%'
        },
        width: plotWidth,
        autosize: true
      }, prefersDarkMode);
      const config = {};
  
      return (<div ref={plotRef}>
            <Plot data={traces} layout={layout} config={config} useResizeHandler />
        </div>
      );
}

FitbarkBatteryChart.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    prefersDarkMode: PropTypes.bool
};

FitbarkBatteryChart.defaultProps = {
    prefersDarkMode: false
};

export default FitbarkBatteryChart;
