import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Paper, CircularProgress, Select, MenuItem } from '@mui/material';
import Grid from '@mui/material/Grid2';

import Plot from 'react-plotly.js';

import { DataFrame } from 'data-forge';

import { getActivityData } from '../../api/strava';
import { titleCase } from '../../utils';

import { handlePlotlyDarkMode } from '../../utils/themeUtils';

const units = {
  heartrate: 'bpm',
  speed: 'mph',
  altitude: 'm',
  grade_smooth: '%',
  velocity_smooth: 'mph',
  pace: 'min / km',
  cadence: 'rpm'
};

const reversedVariables = ['pace', 'heartrate'];

const constructLabel = (value, variable) => {
  const labelUnits = units[variable] ?? '';
  if (value === null || value === undefined || value === '') {
    return `??? ${labelUnits}`;
  }

  return `${value.toFixed(1)} ${labelUnits}`;
};

function StravaActivityDetail({ activityType, activityId, prefersDarkMode }) {
  const plotRef = useRef(null);
  const [plotWidth, setPlotWidth] = useState(null);

  const [processing, setProcessing] = useState(true);
  const [mainVariable, setMainVariable] = useState(
    activityType === 'Ride' ? 'speed' : 'pace'
  );
  const [activityData, setActivityData] = useState(null);
  const [variables, setVariables] = useState(null);
  const [centre, setCentre] = useState(null);
  const [zoom, setZoom] = useState(null);

  useEffect(() => {
    if (plotRef && plotRef.current) {
      setPlotWidth(plotRef.current.getBoundingClientRect().width);
    }
  }, [setPlotWidth]);

  window.addEventListener('resize', () => {
    if (plotRef && plotRef.current) {
      setPlotWidth(plotRef.current.getBoundingClientRect().width);
    }
  });

  useEffect(async () => {
    const data = await getActivityData(activityId);
    const df = new DataFrame(Object.values(data));

    setActivityData(df);
    setVariables(df.getColumnNames());
    setProcessing(false);
  }, [activityId]);

  const selectMain = (event) => {
    const variable = event.target.value;
    setMainVariable(variable);
  };

  const plotMap = (df) => {
    const lats = df.getSeries('latitude').toArray();
    const lons = df.getSeries('longitude').toArray();
    const values = df.getSeries(mainVariable).toArray();
    // const labels = this.constructLabel(values, this.state.mainVariable);
    const labels = values.map((v) => constructLabel(v, mainVariable));

    const colourScale = [
      [0.0, 'rgb(0,0,131)'],
      [0.2, 'rgb(0,60,170)'],
      [0.4, 'rgb(5,255,255)'],
      [0.6, 'rgb(255,255,0)'],
      [0.8, 'rgb(250,0,0)'],
      [1.0, 'rgb(128,0,0)']
    ];

    const colourScaleReversed = [
      [0.0, 'rgb(128,0,0)'],
      [0.04, 'rgb(250,0,0)'],
      [0.16, 'rgb(255,255,0)'],
      [0.36, 'rgb(5,255,255)'],
      [0.64, 'rgb(0,60,170)'],
      [1.0, 'rgb(0,0,131)']
    ];

    return (
      <Grid container spacing={0}>
        <Grid size={12}>
          <Select
            label="Variable"
            value={mainVariable}
            onChange={selectMain}
            variant="outlined"
          >
            {variables.map((v) => (
              <MenuItem key={`menu-item-${v}`} value={v}>
                {titleCase(v.split('_').join(' '))}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid size={12}>
          <Plot
            data={[
              {
                type: 'scattermap',
                mode: 'markers+lines',
                marker: {
                  colorscale: reversedVariables.includes(mainVariable)
                    ? colourScale
                    : colourScaleReversed,
                  color: values,
                  size: 8,
                  colorbar: {
                    thickness: 10,
                    // titleside: 'right',
                    // outlinecolor: 'rgba(68,68,68,0)',
                    // ticks: 'outside',
                    // ticklen: 3,
                    // shoticksuffix: 'last',
                    ticksuffix: units[mainVariable]
                    // dtick: 0.1
                  }
                },
                hoverinfo: 'text',
                hovertext: labels,
                lat: lats,
                lon: lons
              }
            ]}
            layout={handlePlotlyDarkMode({
              margin: {
                r: 0,
                t: 0,
                b: 0,
                l: 0,
                pad: 0
              },
              showlegend: false,
              map: {
                center: centre || {
                  lat: (Math.max(...lats) + Math.min(...lats)) / 2,
                  lon: (Math.max(...lons) + Math.min(...lons)) / 2
                },
                style: prefersDarkMode ? 'dark' : 'basic',
                zoom: zoom || 12.2
              },
              width: plotWidth
            }, prefersDarkMode)}
            config={{
              autosize: true
            }}
            onRelayout={(event) => {
              console.log(event);
              setCentre(event['map.center']);
              setZoom(event['map.zoom']);
            }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Paper
      ref={plotRef}
      style={{
        height: '100%'
      }}
    >
      {processing ? (
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress size={150} />
        </div>
      ) : (
        activityData && plotMap(activityData)
      )}
    </Paper>
  );
}

StravaActivityDetail.propTypes = {
  activityType: PropTypes.string.isRequired,
  activityId: PropTypes.number.isRequired,
  prefersDarkMode: PropTypes.bool
};

StravaActivityDetail.defaultProps = {
  prefersDarkMode: false
};

export default StravaActivityDetail;
