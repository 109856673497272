/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useRef } from 'react';

import { Paper, CircularProgress, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

import useMediaQuery from '@mui/material/useMediaQuery';

import 'weather-icons/css/weather-icons.css';

import { DataFrame } from 'data-forge';
import {
  add,
  set,
  parse,
  parseJSON,
  parseISO,
  format,
  startOfToday
} from 'date-fns';
import chroma from 'chroma-js';
import Plot from 'react-plotly.js';

import {
  getAuroraForecast,
  getAuroraForecastScore,
  getAuroraKIndex,
  getForecast,
  getSatellitePasses,
  getTargets,
  getTargetVisibilities
} from '../api/astro';
import TabSet from '../components/Tabs';
import TabPanel from '../components/TabPanel';

import { handlePlotlyDarkMode } from "../utils/themeUtils";

const processTargetCode = (code) => {
  const regex = /^(?<catalogue>M|IC|NGC)(?<number>\d+)$/;
  const { catalogue, number } = regex.exec(code).groups;
  return {
    catalogue,
    number
  };
};

const expandTargetCode = (code) => {
  const { catalogue, number } = processTargetCode(code);
  const digits = catalogue === 'M' ? 3 : 4;
  return `${catalogue}${number.padStart(digits, '0')}`;
};

function Astro() {
  // ToDo: Either pull these from backend, or allow setting in UI.
  const address = 'Great Oldbury, Stroud, United Kingdom';
  const targetNames = [
    'M31',
    'M42',
    'M45',
    'M81',
    'M20',
    'NGC6523',
    'NGC6992',
    'NGC7293',
    'IC1805',
    'NGC7000',
    'M16',
    'IC443',
    'IC1396'
  ].map(expandTargetCode);
  const visibilityTargetNames = [
    'M31',
    'M42',
    'M45',
    'M81',
    'M101',
    'IC443',
    'NGC2244'
  ].map(expandTargetCode);

  const emoji = {
    space: '🌌',
    satellite: '🛰️',
    dots: {
      red: '🔴',
      orange: '🟠',
      yellow: '🟡',
      green: '🟢',
      blue: '🔵',
      purple: '🟣'
    }
  };

  const moonPhaseIcon = {
    New: 'wi-moon-new',
    'Waxing Crescent': 'wi-moon-waxing-cresent-1',
    'First Quarter': 'wi-moon-first-quarter',
    'Waxing Gibbous': 'wi-moon-waxing-gibbous-4',
    Full: 'wi-moon-full',
    'Waning Gibbous': 'wi-moon-waning-gibbous-4',
    'Third Quarter': 'wi-moon-3rd-quarter',
    'Waning Crescent': 'wi-moon-waning-crescent-5'
  };

  const scoreEmoji = (score) => {
    if (score > 90) return emoji.dots.red;
    if (score > 75) return emoji.dots.orange;
    if (score > 50) return emoji.dots.yellow;
    if (score > 10) return emoji.dots.green;
    return emoji.dots.blue;
  };

  const tabNames = ['Overview', 'Targets', 'Aurora'];

  const [tabValue, setTabValue] = useState(0);

  const [forecast, setForecast] = useState(null);
  const [moonPhase, setMoonPhase] = useState(null);
  const [sunrise, setSunrise] = useState(null);
  const [sunset, setSunset] = useState(null);
  const [visibleTargets, setVisibleTargets] = useState(null);
  // const [issPasses, setIssPasses] = useState(null);

  const [targets, setTargets] = useState(null);
  const [targetVisibilities, setTargetVisibilities] = useState(null);

  const [auroraForecast, setAuroraForecast] = useState(null);
  const [auroraForecastTime, setAuroraForecastTime] = useState(null);
  const [auroraForecastScore, setAuroraForecastScore] = useState(null);
  const [auroraKIndex, setAuroraKIndex] = useState(null);

  const [issPasses, setIssPasses] = useState(null);

  const plotRef = useRef(null);
  const [plotWidth, setPlotWidth] = useState(null);

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  useEffect(() => {
    if (plotRef && plotRef.current) {
      setPlotWidth(plotRef.current.getBoundingClientRect().width);
    }
  }, [setPlotWidth, plotRef]);

  window.addEventListener('resize', () => {
    if (plotRef && plotRef.current) {
      setPlotWidth(plotRef.current.getBoundingClientRect().width);
    }
  });

  const processForecast = async () => {
    if (forecast) return;
    console.log(processForecast.name);
    const data = await getForecast(address);
    const df = new DataFrame(data);
    const firstRow = df.first();
    setMoonPhase(firstRow['moon.phase']);
    setSunrise(parseISO(firstRow.sunrise));
    setSunset(parseISO(firstRow.sunset));
    setForecast(df);
  };

  const processTargets = async () => {
    if (targets) return;
    console.log(processTargets.name);
    const data = await getTargets(address, targetNames);
    const df = new DataFrame(data);
    setTargets(df);
  };

  const processTargetVisibilities = async () => {
    if (targetVisibilities) return;
    console.log(processTargetVisibilities.name);
    const sunsetToday = set(new Date(), {
      hours: 16,
      minutes: 30,
      seconds: 0,
      milliseconds: 0
    });
    const sunriseTomorrow = set(add(new Date(), { days: 1 }), {
      hours: 8,
      minutes: 30,
      seconds: 0,
      milliseconds: 0
    });
    const data = await getTargetVisibilities(
      address,
      visibilityTargetNames,
      sunsetToday,
      sunriseTomorrow
    );
    setVisibleTargets(Object.keys(data));
    const dataFrames = Object.fromEntries(
      Object.entries(data).map(([targetName, targetData]) => [
        targetName,
        new DataFrame(targetData)
      ])
    );
    console.log(dataFrames);
    setTargetVisibilities(dataFrames);
  };

  const processAuroraMap = async () => {
    if (auroraForecast && auroraForecastTime) return;
    console.log(processAuroraMap.name);
    const { data, forecast_time: forecastTime } = await getAuroraForecast();
    const df = new DataFrame(data);
    setAuroraForecast(df);
    setAuroraForecastTime(forecastTime);
  };

  const processAuroraScore = async () => {
    if (auroraForecastScore) return;
    console.log(processAuroraScore.name);
    const data = await getAuroraForecastScore(address);
    console.log(data);
    setAuroraForecastScore(data);
  };

  const processAuroraKIndex = async () => {
    if (auroraKIndex) return;
    console.log(processAuroraKIndex.name);
    const data = await getAuroraKIndex(address);
    const df = new DataFrame(data);
    setAuroraKIndex(df);
  };

  const processISSPasses = async () => {
    if (issPasses) return;
    console.log(processISSPasses.name);
    const data = await getSatellitePasses(address, 'ISS (ZARYA)');
    console.log(data);
    setIssPasses(data.passes);
  };

  const tabRequests = {
    0: [
      processForecast,
      processAuroraScore,
      processISSPasses,
      processTargetVisibilities
    ],
    1: [processTargets, processTargetVisibilities],
    2: [processAuroraKIndex, processAuroraMap]
  };

  useEffect(() => {
    const tabName = tabNames[tabValue];
    console.log(tabName);

    const requests = tabRequests[tabValue];
    Promise.all([requests.map((request) => request())]);
  }, [tabValue]);

  const plotForecast = () => {
    let df = forecast.generateSeries({
      date: (row) => parseISO(row.date),
      day: (row) => parse(row.day, 'yyyy-MM-dd', new Date()),
      dt: (row) => parseISO(row.dt),
      time: (row) => parse(row.time, 'yyyy-MM-dd HH:mm', new Date()),
      label: (row) =>
        `${format(row.date, 'EEE do MMM')} ${format(
          row.time,
          'HH'
        )}:00<br />Score: ${row['score.custom'].toFixed(2)}`
    });

    df = df.where((row) => row.dt >= startOfToday());

    const nColours = 10;

    const scale = chroma
      .scale([
        'rgb(50, 50, 50)',
        'rgb(255, 0, 0)',
        'rgb(255, 140, 0)',
        'rgb(255, 255, 0)',
        'limegreen'
      ])
      .mode('hsl');

    const colours = scale.colors(nColours);

    const plotlyScale = colours.map((c, i) => [i / (nColours - 1), c]);

    const data = [
      {
        type: 'heatmap',
        x: df
          .getSeries('hour')
          .toArray()
          .map((h) => `${h}:00`),
        y: df
          .getSeries('date')
          .toArray()
          .map((d) => format(d, 'EEE do MMM')),
        z: df.getSeries('score.custom').toArray(),
        zmin: 0,
        zmax: 1,
        colorscale: plotlyScale,
        name: 'Astrophotography Score',
        text: df.getSeries('label').toArray(),
        hoverinfo: 'text'
      }
    ];

    const layout = handlePlotlyDarkMode({
      width: plotWidth,
      showlegend: true,
      xaxis: {
        title: '',
        fixedrange: true
      },
      yaxis: {
        title: '',
        fixedrange: true,
        autorange: 'reversed'
      },
      title: `Forecast Score<br />${address}`,
      autosize: true,
      margin: {
        l: 100
      },
      plot_bgcolor: 'rgb(50, 50, 50)'
    }, prefersDarkMode);

    const config = {
      // responsive: true,
      displayModeBar: false
    };

    return <Plot data={data} layout={layout} config={config} />;
  };

  const plotTargets = () => {
    let df = targets.setIndex('Date').dropSeries('Date');

    let scoresToday = [];

    df.getColumnNames().forEach((c) => {
      let series = df.deflate((row) => row[c]);
      series = series.select((v) => Math.max(0, v));

      const sMin = series.min();
      const sMax = series.max();

      series = series.select((v) => (v - sMin) / (sMax - sMin));

      const dateIndex = `${new Date().toISOString().split('T')[0]}T00:00:00`;
      const scoreToday = series.at(dateIndex);
      scoresToday.push({
        target: c,
        score: scoreToday
      });

      df = df.withSeries({
        [c]: series
      });
    });

    scoresToday = scoresToday.sort((a, b) => {
      if (a.score < b.score) return -1;
      if (a.score > b.score) return 1;
      return 0;
    });

    df = df.subset(scoresToday.map((s) => s.target));

    let scores = df.toRows();
    scores = scores[0].map((_, colIndex) => scores.map((row) => row[colIndex]));

    const targetNameStrings = df
      .getColumnNames()
      .map((c) => c.split('|').slice(-1)[0]);

    const data = [
      {
        type: 'heatmap',
        x: df.getIndex().toArray(),
        y: targetNameStrings,
        z: scores,
        colorscale: 'Hot'
      }
    ];

    const layout = handlePlotlyDarkMode({
      width: plotWidth,
      title: `Annual Target Visibility<br />${address}`,
      shapes: [
        {
          type: 'line',
          x0: new Date(),
          x1: new Date(),
          y0: -0.5,
          y1: targetNames.length - 0.5,
          line: {
            color: 'red',
            width: 1
          }
        }
      ],
      autosize: true,
      margin: {
        l: 150
      }
    }, prefersDarkMode);

    const config = {
      // responsive: true,
      displayModeBar: false
    };

    return <Plot data={data} layout={layout} config={config} />;
  };

  const plotTargetVisibilities = () => {
    const column = 'Visible altitude [deg]';
    const date = format(new Date(), 'EEE do MMM');

    const data = Object.entries(targetVisibilities).map(
      ([targetName, targetDf]) => {
        const subDf = targetDf.where((row) => row[column] > 0);

        return {
          type: 'scatter',
          x: subDf.getSeries('Date').toArray(),
          y: subDf.getSeries(column).toArray(),
          name: targetName,
          hoverinfo: 'text',
          hovertext: subDf
            .map(
              (row) =>
                `${format(parseISO(row.Date), 'HH:mm a')}<br />${targetName} ${column
                  .toLowerCase()
                  .replace('deg', '°')}: ${parseFloat(row[column]).toFixed(0)}`
            )
            .toArray()
        };
      }
    );

    const layout = handlePlotlyDarkMode({
      width: plotWidth,
      title: `Target Visibilities - ${date}<br />${address}`,
      xaxis: {
        title: ''
      },
      yaxis: {
        title: column.replace('deg', '°'),
        rangemode: 'nonnegative'
      },
      legend: {
        orientation: 'h'
      },
      autosize: true
    }, prefersDarkMode);

    const config = {
      // responsive: true,
      displayModeBar: false
    };

    return <Plot data={data} layout={layout} config={config} />;
  };

  const plotAuroraMap = (subtext) => {
    const column = 'Aurora';
    const date = format(parseJSON(auroraForecastTime), 'H:mm');

    const minThreshold = 10;

    const filteredDf = auroraForecast.where(
      (row) => row[column] >= minThreshold
    );

    const data = [
      {
        type: 'scattermap',
        lat: filteredDf.getSeries('Latitude').toArray(),
        lon: filteredDf.getSeries('Longitude').toArray(),
        hoverinfo: 'text',
        hovertext: filteredDf.getSeries('Aurora').toArray(),
        marker: {
          color: filteredDf.getSeries('Aurora').toArray(),
          colorscale: 'Jet',
          cmin: minThreshold,
          cmax: 100,
          cauto: false
        }
      }
    ];

    const layout = handlePlotlyDarkMode({
      width: plotWidth,
      title: `Aurora Forecast - ${date}<br />${subtext}`,
      xaxis: {
        title: ''
      },
      yaxis: {
        title: ''
      },
      legend: {
        orientation: 'h'
      },
      map: {
        style: prefersDarkMode ? 'dark' : 'streets',
        center: {
          lat: 51.76,
          lon: -2.31
        },
        zoom: 2
      },
      autosize: true
    }, prefersDarkMode);

    const config = {
      // responsive: true,
      displayModeBar: false,
      mapboxAccessToken: process.env.REACT_APP_MAPBOX_TOKEN
    };

    return <Plot data={data} layout={layout} config={config} />;
  };

  const plotAuroraKIndex = () => {
    const df = auroraKIndex.generateSeries({
      date: (row) => parse(row.time_tag, 'yyyy-MM-dd HH:mm:ss.SSS', new Date())
    });

    const data = [
      {
        type: 'bar',
        x: df.getSeries('date').toArray(),
        y: df.getSeries('Kp').toArray(),
        hoverinfo: 'text',
        hovertext: df
          .map(
            (row) =>
              `${format(row.date, 'EEE do MMM', new Date())}<br />${format(row.date, 'HH:mm', new Date())} - ${format(add(row.date, { hours: 3 }), 'HH:mm', new Date())}<br />Kp: ${row.Kp}`
          )
          .toArray(),
        marker: {
          color: df.getSeries('Kp').toArray(),
          colorscale: 'YlGnBu'
        }
      }
    ];

    const layout = handlePlotlyDarkMode({
      width: plotWidth,
      title: `Planetary K-Index<br />3 hourly windows`,
      xaxis: {
        title: ''
      },
      yaxis: {
        title: 'Kp'
      },
      legend: {
        orientation: 'h'
      },
      autosize: true
    }, prefersDarkMode);

    const config = {
      // responsive: true,
      displayModeBar: false
    };

    return <Plot data={data} layout={layout} config={config} />;
  };

  const progress = (
    <div
      style={{
        height: '457px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress size={150} />
    </div>
  );

  const overviewItem = (title, icon, value) => (
    <Grid size={{ xs: 6, md: 3 }}>
      <Typography gutterBottom>{title}</Typography>
      {value ? (
        icon
      ) : (
        <p>
          <CircularProgress size={36} />
        </p>
      )}
      <Typography>{value}</Typography>
    </Grid>
  );

  return (
    <Grid container spacing={5} width="100%">
      <Grid size={12}>
        <TabSet
          tabNames={tabNames}
          onTabChange={(value) => setTabValue(value)}
        />
      </Grid>
      <Grid size={12} style={{ overflowX: 'hidden' }}>
        <TabPanel index={0} value={tabValue}>
          <Grid container spacing={5}>
            <Grid size={{ xs: 12, lg: 6 }}>
              <Paper ref={plotRef} style={{ padding: 30 }}>
                <Grid container spacing={5}>
                  {overviewItem(
                    'Sunrise',
                    <i
                      className="wi wi-sunrise"
                      style={{ fontSize: 36, color: 'orange' }}
                    />,
                    sunrise ? format(sunrise, 'HH:mm') : ''
                  )}
                  {overviewItem(
                    'Sunset',
                    <i
                      className="wi wi-sunset"
                      style={{ fontSize: 36, color: 'orangered' }}
                    />,
                    sunset ? format(sunset, 'HH:mm') : ''
                  )}
                  {overviewItem(
                    'Moon Phase',
                    <i
                      className={`wi ${moonPhaseIcon[moonPhase]}`}
                      style={{ fontSize: 36, color: 'grey' }}
                    />,
                    moonPhase ?? ''
                  )}
                  {overviewItem(
                    'Visible Targets',
                    <i
                      className="wi wi-stars"
                      style={{ fontSize: 36, color: 'silver' }}
                    />,
                    visibleTargets ? visibleTargets.join(', ') : ''
                  )}
                  {overviewItem(
                    'Aurora score',
                    <p style={{ fontSize: 36 }}>
                      {scoreEmoji(auroraForecastScore?.score)}
                    </p>,
                    auroraForecastScore
                      ? `${auroraForecastScore.score.toFixed(1)}%`
                      : ''
                  )}
                  {overviewItem(
                    'ISS Passes',
                    <p style={{ fontSize: 36 }}>{emoji.satellite}</p>,
                    issPasses ? (
                      <>
                        <div>{`${issPasses.length} in the next 24 hours`}</div>
                        <div>
                          {`Starting at ${format(parseISO(issPasses[0].start), 'HH:mm')}`}
                        </div>
                      </>
                    ) : (
                      ''
                    )
                  )}
                  {/* <p>Meteor showers</p> */}
                </Grid>
              </Paper>
            </Grid>
            <Grid size={{ xs: 12, lg: 6 }}>
              <Paper ref={plotRef}>
                {forecast ? plotForecast() : progress}
              </Paper>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel index={1} value={tabValue}>
          <Grid container spacing={5}>
            <Grid size={{ xs: 12, lg: 6 }}>
              <Paper>
                {targetVisibilities ? plotTargetVisibilities() : progress}
              </Paper>
            </Grid>
            <Grid size={{ xs: 12, lg: 6 }}>
              <Paper>{targets ? plotTargets() : progress}</Paper>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel index={2} value={tabValue}>
          <Grid container spacing={5}>
            <Grid size={{ xs: 12, lg: 6 }}>
              <Paper>{auroraKIndex ? plotAuroraKIndex() : progress}</Paper>
            </Grid>
            <Grid size={{ xs: 12, lg: 6 }}>
              <Paper>
                {auroraForecast && auroraForecastTime && auroraForecastScore
                  ? plotAuroraMap(
                      `Local probability: ${auroraForecastScore.score.toFixed(1)}% ${scoreEmoji(auroraForecastScore.score)}`
                    )
                  : progress}
              </Paper>
            </Grid>
          </Grid>
        </TabPanel>
      </Grid>
    </Grid>
  );
}

export default Astro;
