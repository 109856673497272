import {
    createTheme,
    alpha,
    getContrastRatio
  } from '@mui/material/styles';
  
import { teal, blue, grey } from '@mui/material/colors';

const fitbitBase = '#002A3A';
const stravaBase = '#FC5200';
const fitbarkBase = '#38B7C3';
const threadsBase = '#000000';

const coreTheme = {
  overrides: {
    html: {
      margin: 0,
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
      // -webkit-font-smoothing: antialiased;
      // -moz-osx-font-smoothing: grayscale;
    },
    code: {
      fontFamily:
        "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace"
    }
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  palette: {
    background: {
      main: grey[50],
      dark: grey[900]
    },
    primary: teal,
    secondary: blue,
    fitbark: {
      main: alpha(fitbarkBase, 0.7),
      light: alpha(fitbarkBase, 0.5),
      dark: alpha(fitbarkBase, 0.9),
      contrastText:
        getContrastRatio(alpha(fitbarkBase, 0.7), '#fff') > 4.5
          ? '#fff'
          : '#111'
    },
    fitbit: {
      main: alpha(fitbitBase, 0.7),
      light: alpha(fitbitBase, 0.5),
      dark: alpha(fitbitBase, 0.9),
      contrastText:
        getContrastRatio(alpha(fitbitBase, 0.7), '#fff') > 4.5 ? '#fff' : '#111'
    },
    strava: {
      main: alpha(stravaBase, 0.7),
      light: alpha(stravaBase, 0.5),
      dark: alpha(stravaBase, 0.9),
      contrastText:
        getContrastRatio(alpha(stravaBase, 0.7), '#fff') > 4.5 ? '#fff' : '#111'
    },
    threads: {
      main: alpha(threadsBase, 0.7),
      light: alpha(threadsBase, 0.5),
      dark: alpha(threadsBase, 0.9),
      contrastText:
        getContrastRatio(alpha(threadsBase, 0.7), '#fff') > 4.5
          ? '#fff'
          : '#111'
    }
  }
};

export const lightTheme = createTheme({
  ...coreTheme
});

export const darkTheme = createTheme({
  ...coreTheme,
  palette: {
    ...coreTheme.palette,
    mode: 'dark'
  }
});

export const handlePlotlyDarkMode = (layout, prefersDarkMode) => {
  if (prefersDarkMode) return {
    ...layout,
    paper_bgcolor: '#1e1e1e',
    plot_bgcolor: '#1e1e1e',
    font: {
      ...layout.font,
      color: grey[50]
    }
  };

  return layout;
}
